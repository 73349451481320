import { useEffect, useState } from 'react';

export function usePersistedState<T>(key: string, initialValue: T) {
  const [state, setState] = useState<T>(
    () => (getItem(key) as T) || initialValue
  );

  useEffect(() => {
    setItem(key, state);
  }, [state]);

  return [state, setState] as const;
}

function getItem(key: string) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  } catch (error) {
    console.log(error);
  }
}

function setItem(key: string, value: unknown) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}
