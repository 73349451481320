import { theme } from '../../../../app/theme';
import { Grid } from '../../../../components/ui';
import { formatWeight, formatCurrency } from '../../../../utils/formatting';
import { impactCalculator } from '../../../impact/impactCalculator';
import { tooltipText } from '../../constants';
import { InsightBox } from '../components/InsightBox';
import { ImpactType, ImpactBase } from '../model';

export interface ImpactProps {
  impactType: ImpactType;
  impactBase: ImpactBase;
  foodWasteInPounds: number;
  dropOffs: any[];
  householdsParticipating: number;
}

export function ImpactGrid({
  impactType,
  impactBase,
  householdsParticipating,
  foodWasteInPounds,
  dropOffs,
}: ImpactProps) {
  function getImpact() {
    if (impactType === 'current-rate') {
      return impactCalculator.impactRateYearly(householdsParticipating);
    }

    if (impactBase === 'drop-off') {
      return impactCalculator.impactByDropOffs(dropOffs);
    } else {
      return impactCalculator.impactByFoodWaste(foodWasteInPounds);
    }
  }

  const { foodWasteSaved, ghg, soilCreated, feesAvoided } = getImpact();

  const suffix = impactType === 'current-rate' ? '/year' : '';

  return (
    <Grid col="repeat(2, 1fr)" gap="1rem">
      <InsightBox
        title="Scraps saved from landfill"
        value={`${formatWeight(foodWasteSaved)}${suffix}`}
        icon="apple-alt"
        color={theme.colors.primary}
        tooltipText={tooltipText.foodWasteAccumulated}
      />
      <InsightBox
        title="GHG emissions prevented"
        value={`${formatWeight(ghg)}${suffix}`}
        icon="smog"
        color="#2f6fb1"
        tooltipText={tooltipText.ghg}
      />
      <InsightBox
        title="New soil created"
        value={`${formatWeight(soilCreated)}${suffix}`}
        icon="seedling"
        color="#82671F"
        tooltipText={tooltipText.soil}
      />
      <InsightBox
        title="Landfill fees avoided"
        value={`${formatCurrency(feesAvoided)}${suffix}`}
        icon="dollar"
        color="#616161"
        tooltipText={tooltipText.fees}
      />
    </Grid>
  );
}
