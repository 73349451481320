import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

type Props = {
  initialized: boolean;
  children: ReactElement;
};

export const AnalyticsTracker = ({ initialized, children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (initialized && !!(window as any).ga) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [initialized, location.pathname]);

  return children;
};
