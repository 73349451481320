import React from 'react';
import styled from 'styled-components';

const PageHeaderContainer = styled.div`
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fff;
  padding: 1rem;
  border-bottom: 1px solid #d7d7d7;

  color: #969696;
  font-size: 0.95rem;
`;

const PageTitle = styled.div`
  font-weight: 500;
`;

export type PageHeaderProps = {
  left?: React.ReactNode;
  pageTitle: string;
  right?: React.ReactNode;
  className?: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  left,
  pageTitle,
  right,
  className,
}) => {
  return (
    <PageHeaderContainer className={className}>
      {left && left}
      {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
      {right && right}
    </PageHeaderContainer>
  );
};
