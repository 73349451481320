import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MyImpact } from './MyImpact';
import { TotalImpact } from './TotalImpact';
import Switch from '../../../components/Switch';
import { useSoilSiteContext } from '../SoilSiteContext';
import { ImpactBase } from '../../premium/insights/model';
import { Button, Flex, Revealer } from '../../../components/ui';

type View = 'total-impact' | 'my-impact';

type Props = {
  onRegisterDropOff: () => void;
  onAddSupporters: () => void;
};

export function SiteImpact({ onRegisterDropOff, onAddSupporters }: Props) {
  const { soilSite } = useSoilSiteContext();

  const [view, setView] = useState<View>('total-impact');
  const [impactBase, setImpactBase] = useState<ImpactBase>('membership');

  const handleViewSelected = (view: View) => {
    setView(view);
    if (impactBase === 'drop-off') {
      setImpactBase('membership');
    }
  };

  return (
    <Container>
      <Header align="center" justify="space-between">
        <Flex align="center" gap="0.325rem">
          <Icon>
            <FontAwesomeIcon icon="trophy" />
          </Icon>
          <p style={{ margin: 0 }}>
            IMPACT at <strong>{soilSite.name}</strong>
          </p>
        </Flex>
      </Header>

      <Switch<View>
        onSelected={handleViewSelected}
        defaultValue={view}
        fullWidth
        flat
        style={{
          fontSize: '0.815rem',
          // padding: '0.75rem',
        }}
      >
        <SwitchButton value="total-impact">total impact</SwitchButton>
        <SwitchButton value="my-impact">My Impact</SwitchButton>
      </Switch>

      <BlockContainer style={{ marginTop: '0.75rem' }}>
        <Switch<ImpactBase>
          onSelected={setImpactBase}
          defaultValue={impactBase}
        >
          <Switch.SwitchButton value="membership">
            participation length
          </Switch.SwitchButton>
          <Switch.SwitchButton value="drop-off">
            drop-offs recorded
          </Switch.SwitchButton>
        </Switch>
      </BlockContainer>

      <Revealer isOpen={view === 'total-impact'}>
        <BlockContainer>
          <TotalImpact impactBase={impactBase} />

          <small
            style={{
              display: 'block',
              marginTop: '0.75rem',
            }}
          >
            To see more impact,{' '}
            <Button variant="link" onClick={() => onAddSupporters()}>
              add more Soil Supporters
            </Button>
            .
          </small>
        </BlockContainer>
      </Revealer>

      <Revealer isOpen={view === 'my-impact'}>
        <BlockContainer>
          <MyImpact impactBase={impactBase} />
        </BlockContainer>
      </Revealer>

      <BlockContainer>
        <Button
          onClick={onRegisterDropOff}
          style={{ width: '100%', marginTop: '0.75rem' }}
        >
          <FontAwesomeIcon icon="file-signature" /> Record a drop-off
        </Button>
      </BlockContainer>
    </Container>
  );
}

const Header = styled(Flex)`
  border-bottom: 1px solid #dedede;
  padding: 0.65rem 0.75rem;

  p {
    margin: 0;
  }
`;

const Icon = styled.div`
  padding: 0.25rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  box-shadow: 0 0 3px rgba(91, 91, 91, 0.1);

  p {
    margin: 0;
    font-size: 0.925rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
    }
  }
`;

const BlockContainer = styled.div`
  padding: 0 0.75rem;
`;

const SwitchButton = styled(Switch.SwitchButton)`
  border-bottom: 3px solid transparent !important;
  border-top: 3px solid transparent !important;
  font-weight: 500;

  &.active {
    ${({ theme }) =>
      `
      background-color: unset !important;
      border: unset !important;
      border-bottom: 3px solid ${theme.colors.primary} !important;
      border-top: 3px solid transparent !important;
      color: ${theme.colors.primary} !important;
    `}
  }
`;
