import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

type SwitchValue = string | number | readonly string[];

const SwitchContext = createContext(undefined);

type SwitchProps<SwitchValue> = React.ComponentProps<'div'> & {
  children: any[];
  defaultValue?: SwitchValue;
  fullWidth?: boolean;
  flat?: boolean;
  onSelected?: (value: SwitchValue) => void;
};

export default function Switch<SwitchValue>({
  children,
  defaultValue,
  onSelected,
  className,
  style,
  fullWidth = false,
  flat = false,
}: SwitchProps<SwitchValue>) {
  const [currentValue, setCurrentValue] = useState<SwitchValue>(
    defaultValue || children[0].props.value
  );

  useEffect(() => {
    onSelected && onSelected(currentValue);
  }, [currentValue]);

  // allows to control the switch from a parent element
  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  return (
    <SwitchContext.Provider value={{ currentValue, setCurrentValue }}>
      <SwitchContainer className={className} style={style}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement<SwitchButtonProps>(child)) {
            throw new Error('Switch children must be Switch.Button components');
          }
          // Explicitly validate the value type
          if (typeof child.props.value === 'undefined') {
            throw new Error('SwitchButton must have a value prop');
          }
          return React.cloneElement(child, {
            fullWidth,
            flat,
            className,
          });
        })}
      </SwitchContainer>
    </SwitchContext.Provider>
  );
}

type SwitchButtonProps = React.ComponentProps<'label'> & {
  value: SwitchValue;
  fullWidth?: boolean;
  flat?: boolean;
};

function SwitchButton<SwitchValue>({
  children,
  value,
  fullWidth,
  flat,
  className,
  style,
}: SwitchButtonProps) {
  const { currentValue, setCurrentValue } = useContext(SwitchContext);

  return (
    <StyledSwitchButton
      htmlFor={value.toString()}
      fullWidth={fullWidth}
      flat={flat}
      className={`${className} ${currentValue === value ? 'active' : ''}`}
      style={style}
    >
      <input
        type="radio"
        id={value.toString()}
        value={value}
        checked={currentValue === value}
        onChange={() => setCurrentValue(value)}
      />

      {children}
    </StyledSwitchButton>
  );
}

Switch.SwitchButton = SwitchButton;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  text-transform: uppercase;
`;

const StyledSwitchButton = styled.label<{
  activeColor?: string;
  fullWidth?: boolean;
  flat?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  ${({ fullWidth }) => fullWidth && `flex: 1;`}

  padding: 0.5rem 0.75rem;

  background-color: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 4px;

  ${({ flat }) => flat && `border-radius: 0;`}

  :has(input[type='radio']:checked) {
    background-color: ${({ theme, activeColor }) =>
      activeColor || theme.colors.primary};
    border: 1px solid
      ${({ theme, activeColor }) => activeColor || theme.colors.primary};
    color: #fff;
    box-shadow: none;
  }

  input[type='radio'] {
    display: none;
    visibility: hidden;
  }

  :hover {
    cursor: pointer;
    background-color: #ebebeb;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
    ${({ flat }) => flat && `border-radius: 0;`}
  }

  :last-child {
    border-radius: 0 4px 4px 0;
    ${({ flat }) => flat && `border-radius: 0;`}

    border-left: 0;
  }

  transition: all 0.25s ease;
`;
