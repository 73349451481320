import styled from 'styled-components';
import { Badge, ColorVariant } from './ui/Badge';
import { ComponentProps } from 'react';
import { Flex } from './ui';

type Props = {
  count: string | number;
  text: string;
  variant?: ColorVariant;
  backgroundColor?: string;
} & ComponentProps<'div'>;

export function Counter({
  count,
  text,
  variant = 'primary',
  backgroundColor,
  children,
}: Props) {
  const zeroCount = ['0 lb', '0 kg', 0];
  const isZeroCount = zeroCount.includes(count);

  const colorVariant = isZeroCount ? 'error' : variant;

  return (
    <CounterContainer backgroundColor={backgroundColor}>
      <Flex align="center" gap="0.5rem" style={{ flex: 1 }}>
        <Badge
          variant={colorVariant}
          style={{ whiteSpace: 'nowrap', minWidth: '60px' }}
        >
          {count}
        </Badge>

        <TextContainer>
          <h5>{text}</h5>
        </TextContainer>
      </Flex>

      {children}
    </CounterContainer>
  );
}

const CounterContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dedede;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#fefefe'};
  padding: 0.5rem;

  flex: 1;

  &:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    &:first-child {
      border-radius: 0.25rem 0 0 0.25rem;
    }

    &:last-child {
      border-radius: 0 0.25rem 0.25rem 0;
    }

    border-left: 1px solid #dedede;
  }
`;

const TextContainer = styled.div`
  h3,
  h5,
  h5 {
    margin: 0;
  }
`;
