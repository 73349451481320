import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../app/theme';
import { Tooltip } from '../../components/ui/Tooltip';

export function ImpactTooltip({ visible = true, children }) {
  if (!visible) return null;

  return (
    <Tooltip
      triggerElement={
        <span style={{ fontSize: '1rem' }}>
          <FontAwesomeIcon icon="info-circle" color={theme.colors.primary} />
        </span>
      }
    >
      {children}
    </Tooltip>
  );
}
