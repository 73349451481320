import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, DropMenu } from '../../components/ui';
import { isMobile } from '../../utils/helpers';
import { useCurrentSiteUser, useSoilSiteContext } from './hooks';
import { InviteModalTab } from './modals/InviteModal';

type Props = {
  onRegisterDropOff: () => void;
  onAddSupporters: (tab: InviteModalTab) => void;
};

export const SiteViewHeader = ({
  onRegisterDropOff,
  onAddSupporters,
}: Props) => {
  const { soilSite } = useSoilSiteContext();
  const { iAmSoilMaker } = useCurrentSiteUser(soilSite);

  const showRegisterDropOffButton = !iAmSoilMaker && isMobile();
  // for supporters do not show on mobile
  const showAddSupportersButton =
    (!iAmSoilMaker && !isMobile()) || iAmSoilMaker;

  return (
    <SiteHeader>
      <BreadCrumbs>
        <Link to="/my-soil-sites">
          <FontAwesomeIcon icon="chevron-left" /> My Soil Sites
        </Link>
        <span>
          / <strong>{soilSite.name}</strong>
        </span>
      </BreadCrumbs>

      <SiteHeaderMenu>
        {showRegisterDropOffButton && (
          <Button onClick={onRegisterDropOff}>
            <FontAwesomeIcon icon="file-signature" /> Record a drop-off
          </Button>
        )}
        {showAddSupportersButton && (
          <DropMenu
            triggerElement={
              <Button>
                <FontAwesomeIcon icon="user-plus" /> Add Soil Supporters
              </Button>
            }
          >
            <span onClick={() => onAddSupporters('qr')}>
              <FontAwesomeIcon icon="qrcode" /> Use your QR code
            </span>
            <span onClick={() => onAddSupporters('link')}>
              <FontAwesomeIcon icon="share" /> Share your link
            </span>
            <span onClick={() => onAddSupporters('poster')}>
              <FontAwesomeIcon icon="file-image" /> Use your poster
            </span>
            {/* <span onClick={() => onAddSupporters('email')}>
              <FontAwesomeIcon icon="envelope" /> Invite by email
            </span> */}
          </DropMenu>
        )}
      </SiteHeaderMenu>
    </SiteHeader>
  );
};

const SiteHeader = styled.div`
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  border-bottom: 1px solid #d7d7d7;
`;

const BreadCrumbs = styled.div`
  color: #969696;
  font-size: 0.95rem;

  padding: 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1.25rem 1rem;
  }

  a {
    margin-left: 0.15rem;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    span {
      display: none;
    }
  }
`;

const SiteHeaderMenu = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0 0.5rem;

  button > div {
    padding: 0.5rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 0.75rem;
    padding: 0 1rem;

    button > div {
      padding: 0.5rem 1rem;
    }
  }
`;
