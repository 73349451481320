import { lazy, Suspense, useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGoogleMaps } from './app/useGoogleMaps';
import { SideNav, Header, Loader, ErrorComponent } from './components/ui';

import {
  Learn,
  Plan,
  Privacy,
  Resources,
  SoilMakerBox,
  Terms,
} from './pages/static';

import {
  AcceptInvitation,
  ContactUs,
  Login,
  Map,
  Messages,
  MySoilSites,
  MobileAppLanding,
  Organics,
  SendCreatePassword,
  CreateNewPassword,
  Signup,
  SoilSite,
  SoilSiteWizard,
  UserAccount,
  UserProfile,
  VerifyAccount,
  Garden,
  Home,
  Donation,
  MagicLogin,
  LoginWithoutPassword,
  CreatePassword,
} from './pages';

import Insights from './features/premium/insights/InsightsPage';
import Sponsor from './features/donate/sponsor/Sponsor';

import { useAnalytics, AnalyticsTracker } from './analytics';
import { isMobileApp, mobileSetToken } from './mobile/mobile';
import { useAuthenticate } from './features/auth/useAuthenticate';
import { isAdmin } from './features/auth/utils';
import { PrivateRoute } from './features/auth/components/PrivateRoute';
import { useMeQuery } from './hooks/useMeQuery';
import { useNotificationSubscription } from './features/notifications/useNotificationSubscription';
import { useAuthUser } from './features/auth/useAuthUser';
import { useFetchUnreadMessageCount } from './hooks/useFetchUnreadMessageCount';
import { useDetectScreenSizeChange } from './hooks/useDetectScreenSizeChange';

import { MobileHelper } from './mobile/MobileHelper';
import { NewVersionPopup } from './features/version/NewVersionPopup';
import { MobileAppBadges } from './components/MobileAppBadges';
import { MobileBanner } from './components/MobileBanner';
import { mobileAppStoreUrls } from './utils/config';

import {
  ChooseYourRole,
  HowDidYouHearPage,
  RequestSiteAccess,
  SoilSitesNearYou,
  StaySafe,
  YourName,
  VerifyContactInfo,
  YourLocation,
} from './features/onboarding';

import PremiumInsightsInquiry from './features/premium/InsightsInquiry';
import PremiumMultiInquiry from './features/premium/MultiInquiry';
import ActivateProductPage from './features/premium/activate/ActivateProductPage';
import ActivateRouterPage from './features/premium/ActivateRouter';

const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));

const App = () => {
  const authUser = useAuthUser();
  const { isMapLoaded, mapLoadError } = useGoogleMaps();

  useAuthenticate();
  useMeQuery();
  useNotificationSubscription();
  useFetchUnreadMessageCount();
  useDetectScreenSizeChange();

  const consentGiven = true;
  const { initialized } = useAnalytics(consentGiven);

  useEffect(() => {
    if (!!(window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        send_to: 'AW-10906726604/5ES5CNuz3YAYEMzh3dAo',
      });
    }
  }, []);

  const isUserAdmin = isAdmin(authUser);
  // user must be part of organization (and a role?)
  const canViewInsights = false;

  if (!isMapLoaded) return <Loader />;
  if (mapLoadError) return <ErrorComponent error={mapLoadError} />;

  return (
    <Router>
      <MobileHelper />
      <StyledToastContainer />
      <AnalyticsTracker initialized={initialized}>
        <Switch>
          {/* App Layout */}
          <Route>
            <AppContainer className="App">
              <Header
                extraNav={
                  <MobileAppBadges
                    popupStyle={{
                      top: '2.5rem',
                      right: '0',
                    }}
                    badgeStyle={{ width: '128px' }}
                  />
                }
              />
              <SideNav showAdmin={isUserAdmin} showInsights={canViewInsights} />
              <Main className="main">
                {/* Broken in the app so disabled for mobile for now */}
                {!isMobileApp() && <NewVersionPopup />}

                <MobileBanner
                  excludePaths={[
                    '/',
                    '/download-makesoil-mobile-app',
                    '/the-garden',
                  ]}
                />

                <Switch>
                  {/* Auth Pages */}
                  <Route
                    path={[
                      '/login',
                      '/log-in-without-password',
                      '/magiclogin',
                      '/forgot-password',
                      '/create-new-password',
                      '/signup',
                      '/verify',
                      '/invitation',
                      '/load',
                    ]}
                  >
                    <Switch>
                      <Route
                        exact
                        path="/load/:token"
                        render={(props) => {
                          void mobileSetToken(props.match.params.token);
                          return <Redirect to="/" />;
                        }}
                      />
                      <Route exact path="/login" render={() => <Login />} />
                      <Route
                        exact
                        path="/magiclogin/:magicId"
                        component={MagicLogin}
                      />

                      <Route exact path="/signup" render={() => <Signup />} />
                      <Route
                        exact
                        path="/verify"
                        render={() => <VerifyAccount />}
                      />

                      <Route
                        exact
                        path="/forgot-password"
                        component={SendCreatePassword}
                      />
                      <Route
                        exact
                        path="/create-new-password"
                        component={CreateNewPassword}
                      />
                      <Route
                        exact
                        path="/log-in-without-password"
                        component={LoginWithoutPassword}
                      />

                      <Route
                        exact
                        path="/invitation/:siteIdentifier"
                        component={AcceptInvitation}
                      />
                    </Switch>
                  </Route>

                  {/* Onboarding Pages */}
                  <Route
                    path={[
                      '/onboarding/your-location',
                      '/onboarding/how-did-you-hear',
                      '/onboarding/create-password',
                      '/onboarding/choose-your-role',
                      '/onboarding/soil-sites-near-you',
                      '/onboarding/your-name',
                      '/onboarding/verify-contact-info',
                      '/onboarding/stay-safe',
                      '/onboarding/request-access',
                      '/onboarding/donate-to-repair-earth',
                      '/onboarding/thank-you-for-your-donation',
                    ]}
                  >
                    <Switch>
                      <PrivateRoute
                        exact
                        path="/onboarding/your-location"
                        component={YourLocation}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/how-did-you-hear"
                        component={HowDidYouHearPage}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/create-password"
                        component={CreatePassword}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/choose-your-role"
                        component={ChooseYourRole}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/soil-sites-near-you"
                        component={SoilSitesNearYou}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/your-name"
                        component={YourName}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/verify-contact-info"
                        component={VerifyContactInfo}
                      />
                      <PrivateRoute
                        exact
                        path="/onboarding/stay-safe"
                        component={StaySafe}
                      />
                      <PrivateRoute
                        exact
                        path="/onboarding/request-access"
                        component={RequestSiteAccess}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/donate-to-repair-earth"
                        component={Donation}
                      />

                      <PrivateRoute
                        exact
                        path="/onboarding/thank-you-for-your-donation"
                        component={Donation}
                      />
                    </Switch>
                  </Route>

                  {/* Pro */}

                  <Route
                    exact
                    path="/pro/access-activate"
                    component={ActivateRouterPage}
                  />

                  <Route
                    exact
                    path="/activate"
                    component={ActivateProductPage}
                  />

                  <Route
                    exact
                    path="/activate/inquiry"
                    component={PremiumInsightsInquiry}
                  />

                  <PrivateRoute
                    exact
                    path="/pro/activate"
                    component={Insights}
                  />

                  <PrivateRoute
                    exact
                    path="/pro/multi-inquiry"
                    component={PremiumMultiInquiry}
                  />

                  <Route exact path="/soil-sponsors" component={Sponsor} />

                  <Route
                    exact
                    path={['/tscan', '/hyd', '/coffeescan', '/timevalues2023']}
                    render={() => <Redirect to="/" />}
                  />

                  <Route
                    exact
                    path={['/iosm', '/iose', '/ios']}
                    render={() => {
                      window.location.replace(mobileAppStoreUrls.appleStore);
                      return null;
                    }}
                  />

                  <Route
                    exact
                    path={['/andm', '/ande', '/android']}
                    render={() => {
                      window.location.replace(
                        mobileAppStoreUrls.googlePlayStore
                      );
                      return null;
                    }}
                  />

                  {/* <Route path="/impact/:userHashId" component={UserImpact} /> */}

                  <Route
                    exact
                    path="/download-makesoil-mobile-app"
                    component={MobileAppLanding}
                  />

                  <Route exact path="/organics" component={Organics} />

                  <Route path="/map" component={Map} />

                  <PrivateRoute
                    path="/the-garden"
                    component={Garden}
                    condition={isAdmin}
                  />

                  <PrivateRoute
                    exact
                    path="/create-soil-site"
                    component={SoilSiteWizard}
                  />

                  {/* For Sunny */}
                  <PrivateRoute
                    exact
                    path="/site/:siteIdentifier"
                    component={SoilSite}
                  />

                  <PrivateRoute
                    exact
                    path="/my-soil-sites/:siteIdentifier/edit"
                    component={SoilSite}
                  />
                  <PrivateRoute
                    exact
                    path="/my-soil-sites/:siteIdentifier"
                    component={SoilSite}
                  />
                  <PrivateRoute
                    exact
                    path="/my-soil-sites"
                    component={MySoilSites}
                  />

                  <PrivateRoute path="/messages" component={Messages} />

                  <PrivateRoute
                    exact
                    path="/user/:userHashId"
                    component={UserProfile}
                  />

                  <PrivateRoute exact path="/account" component={UserAccount} />

                  <Route exact path="/contact-us" component={ContactUs} />

                  <Route exact path="/" component={Home} />
                </Switch>

                {/* Static Pages */}
                <Suspense fallback={<Loader />}>
                  <Route exact path="/learn" component={Learn} />
                  <Route
                    exact
                    path="/posters-and-flyers"
                    component={Resources}
                  />
                  <Route exact path="/diy" component={SoilMakerBox} />
                  <Route exact path="/plan" component={Plan} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/terms" component={Terms} />
                </Suspense>

                <Suspense fallback={<Loader />}>
                  <Switch>
                    <PrivateRoute
                      path="/admin"
                      component={AdminDashboard}
                      condition={isAdmin}
                    />
                  </Switch>
                </Suspense>
                {/* 
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <PrivateRoute
                      path="/premium"
                      component={PremiumDashboard}
                      condition={() => true}
                    />
                  </Switch>
                </Suspense> */}
              </Main>
            </AppContainer>
          </Route>
        </Switch>
      </AnalyticsTracker>
    </Router>
  );
};

export default App;

const AppContainer = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;

  grid-template-areas:
    'header header'
    'side-nav main';

  overflow: hidden;
`;

const Main = styled.main`
  grid-area: main;
  overflow: hidden;
`;

const StyledToastContainer = styled(ToastContainer)`
  padding-top: env(safe-area-inset-top);

  .Toastify__toast--default {
    background: ${({ theme }) => theme.colors.grey};
  }

  .Toastify__toast--info {
    background: ${({ theme }) => theme.colors.info};
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast--warning {
    background: ${({ theme }) => theme.colors.warning};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.error};
  }
`;
