import styled from 'styled-components';

import { Counter } from '../../../components/Counter';
import { impactCalculator } from '../../impact/impactCalculator';
import { useSoilSiteContext } from '../SoilSiteContext';
import { ImpactTooltip } from '../../impact/ImpactTooltip';
import { Loader, Revealer } from '../../../components/ui';
import { ImpactBase } from '../../premium/insights/model';
import { tooltipText } from '../../premium/constants';
import { formatWeight } from '../../../utils/formatting';
import { gql, useQuery } from '@apollo/client';

const FOOD_WASTE_ACCUMULATED = gql`
  query FoodWasteAccumulatedAtSoilSite($id: Int!) {
    foodWasteAccumulatedAtSoilSite(id: $id)
  }
`;

export function TotalImpact({ impactBase }: { impactBase: ImpactBase }) {
  const {
    soilSite: { id, members, dropOffs },
  } = useSoilSiteContext();

  const { data, loading } = useQuery(FOOD_WASTE_ACCUMULATED, {
    variables: { id },
  });

  if (loading) return <Loader />;

  const foodWasteInPounds = data.foodWasteAccumulatedAtSoilSite;

  function getImpact() {
    if (impactBase === 'drop-off') {
      return impactCalculator.impactByDropOffs(dropOffs);
    } else {
      return impactCalculator.impactByFoodWaste(foodWasteInPounds);
    }
  }

  const { foodWasteSaved, ghg, soilCreated } = getImpact();

  return (
    <div style={{ paddingTop: '0.75rem' }}>
      <Counters>
        <Revealer isOpen={impactBase === 'membership'}>
          <Counter count={members.length} text="Households participating" />
        </Revealer>

        <Revealer isOpen={impactBase === 'drop-off'}>
          <Counter count={dropOffs.length} text="Total drop-offs recorded" />
        </Revealer>

        <Counter
          count={formatWeight(foodWasteSaved)}
          text="Nutrient-rich scraps saved from landfill"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          {impactBase === 'membership' && (
            <ImpactTooltip>
              This is an estimate based on the number of unique households
              registered at this soil site, how long each of those households
              has been registered, and the average weight of US household food
              waste per year that can be composted (according to ReFED).
            </ImpactTooltip>
          )}

          {impactBase === 'drop-off' && (
            <ImpactTooltip>{tooltipText.dropOff}</ImpactTooltip>
          )}
        </Counter>

        <Counter
          count={formatWeight(ghg)}
          text="GHG emissions prevented"
          variant="info"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          <ImpactTooltip>{tooltipText.ghg}</ImpactTooltip>
        </Counter>

        <Counter
          count={formatWeight(soilCreated)}
          text="New soil created (or that will be)"
          variant="brown"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          <ImpactTooltip>{tooltipText.soil}</ImpactTooltip>
        </Counter>
      </Counters>
    </div>
  );
}

const Counters = styled.div`
  border: 1px solid #dedede;
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
