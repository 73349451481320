import { Counter } from '../../components/Counter';
import { formatWeight } from '../../utils/formatting';
import { tooltipText } from '../premium/constants';
import { ImpactTooltip } from './ImpactTooltip';

type Props = {
  foodWasteSaved: number;
  ghg: number;
  soilCreated: number;
};

export function ImpactCounters({ foodWasteSaved, ghg, soilCreated }: Props) {
  return (
    <>
      <Counter
        count={formatWeight(foodWasteSaved)}
        text="Nutrient-rich scraps saved from landfill"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      >
        <ImpactTooltip>{tooltipText.foodWasteAccumulated}</ImpactTooltip>
      </Counter>
      <Counter
        count={formatWeight(ghg)}
        text="GHG emissions prevented"
        variant="info"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      >
        <ImpactTooltip>{tooltipText.ghg}</ImpactTooltip>
      </Counter>
      <Counter
        count={formatWeight(soilCreated)}
        text="New soil created (or that will be)"
        variant="brown"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      >
        <ImpactTooltip>{tooltipText.soil}</ImpactTooltip>
      </Counter>
    </>
  );
}
