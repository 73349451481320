import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { credentials } from '../utils/config';

export const useAnalytics = (initialize: boolean) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialize && !initialized) {
      initializeGA();
    }
  }, [initialize, initialized]);

  function initializeGA() {
    return new Promise((resolve) => {
      ReactGA.initialize([
        {
          trackingId: credentials.get('GA4_KEY'),
        },
      ]);
      if (!!(window as any).ga) resolve(true);
    }).then(() => {
      setInitialized(!!(window as any).ga);
    });
  }

  function removeAnalyticsCookies() {
    // todo
  }

  return { initialized, initializeGA, removeAnalyticsCookies };
};
