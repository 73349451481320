import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';

import { useAuthUser } from '../../auth/useAuthUser';
import { useSoilSiteContext } from '../SoilSiteContext';
import { isAdmin } from '../../auth/utils';
import { impactCalculator } from '../../impact/impactCalculator';
import { Counter } from '../../../components/Counter';
import { Loader, Revealer } from '../../../components/ui';
import { ImpactBase } from '../../premium/insights/model';
import { formatWeight } from '../../../utils/formatting';
import { ImpactTooltip } from '../../impact/ImpactTooltip';
import { tooltipText } from '../../premium/constants';

const FOOD_WASTE_ACCUMULATED = gql`
  query FoodWasteAccumulatedByUserAtSoilSite($siteId: Int!) {
    foodWasteAccumulatedByUserAtSoilSite(siteId: $siteId)
  }
`;

export function MyImpact({ impactBase }: { impactBase: ImpactBase }) {
  const authUser = useAuthUser();

  const {
    soilSite: { id, members },
  } = useSoilSiteContext();

  const { data, loading } = useQuery(FOOD_WASTE_ACCUMULATED, {
    variables: { siteId: id },
  });

  if (loading) return <Loader />;

  const foodWasteInPounds = data.foodWasteAccumulatedByUserAtSoilSite;

  const participant = members.find((p) => p.userId === authUser.id);

  // if non member Admin visits the site use empty array for my drop offs
  const myDropOffs =
    !participant && isAdmin(authUser) ? [] : participant.dropOffs;

  function getImpact() {
    if (impactBase === 'drop-off') {
      return impactCalculator.impactByDropOffs(myDropOffs);
    } else {
      return impactCalculator.impactByFoodWaste(foodWasteInPounds);
    }
  }

  const { foodWasteSaved, ghg, soilCreated } = getImpact();

  return (
    <div style={{ paddingTop: '0.75rem' }}>
      <Counters>
        <Revealer isOpen={impactBase === 'drop-off'}>
          <Counter count={myDropOffs.length} text="My drop-offs recorded" />
        </Revealer>

        <Counter
          count={formatWeight(foodWasteSaved)}
          text="Nutrient-rich scraps saved from landfill"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          {impactBase === 'membership' && (
            <ImpactTooltip>
              This is an estimate based on how long you’ve been part of this
              soil site, and the average weight of US household food waste per
              year that can be composted (according to ReFED).
            </ImpactTooltip>
          )}

          {impactBase === 'drop-off' && (
            <ImpactTooltip>{tooltipText.dropOff}</ImpactTooltip>
          )}
        </Counter>

        <Counter
          count={formatWeight(ghg)}
          text="GHG emissions prevented"
          variant="info"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          <ImpactTooltip>{tooltipText.ghg}</ImpactTooltip>
        </Counter>

        <Counter
          count={formatWeight(soilCreated)}
          text="New soil created (or that will be)"
          variant="brown"
          backgroundColor="rgba(69, 177, 57, 0.09)"
        >
          <ImpactTooltip>{tooltipText.soil}</ImpactTooltip>
        </Counter>
      </Counters>
    </div>
  );
}

const Counters = styled.div`
  border: 1px solid #dedede;
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
