import { WeightUnit, getWeightUnit } from '../../utils/formatting';

const defaultWeightUnit: WeightUnit = 'pound';
const averageDropOffWeightInLbs = 9.4;
const ghgMulitplier = 0.7;
const soilMultiplier = 0.1;
const feesAvoidedMultiplier = 57.63;
const poundsInATon = 2000;

// in USA from 2022
const averageHouselholdSize = 2.6;
const averageFoodScrapsPerPersonPerYearInPounds = 280;

export const impactCalculator = {
  impactRateYearly(householdsParticipating: number) {
    const weightPerYearInPounds =
      householdsParticipating *
      averageHouselholdSize *
      averageFoodScrapsPerPersonPerYearInPounds;

    return this.calculateImpact(weightPerYearInPounds);
  },

  impactByFoodWaste(weightInPounds: number) {
    return this.calculateImpact(weightInPounds);
  },

  impactByDropOffs(dropOffs: { weight?: number }[]) {
    const foodWasteSaved = dropOffs.reduce(
      (accumulator, dropOff) =>
        accumulator + (dropOff.weight || averageDropOffWeightInLbs),
      0
    );

    return this.calculateImpact(foodWasteSaved);
  },

  ghg(foodWasteSaved: number): number {
    return foodWasteSaved * ghgMulitplier;
  },

  soilCreated(foodWasteSaved: number): number {
    return foodWasteSaved * soilMultiplier;
  },

  feesAvoided(foodWasteSaved: number): number {
    return (foodWasteSaved / poundsInATon) * feesAvoidedMultiplier;
  },

  kgToLbs(weight: number): number {
    return weight * 2.2046;
  },

  lbsToKg(weight: number): number {
    return weight / 2.2046;
  },

  calculateImpact(foodWasteWeightInPounds: number) {
    const weightUnit = getWeightUnit();
    const useDefaultWeightUnit = weightUnit.unit === defaultWeightUnit;

    const foodWasteSaved = useDefaultWeightUnit
      ? foodWasteWeightInPounds
      : this.lbsToKg(foodWasteWeightInPounds);

    const ghg: number = this.ghg(foodWasteSaved);
    const soilCreated: number = this.soilCreated(foodWasteSaved);
    const feesAvoided: number = this.feesAvoided(foodWasteSaved);

    return {
      foodWasteSaved,
      ghg,
      soilCreated,
      feesAvoided,
    };
  },
};
