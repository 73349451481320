import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Grid, Revealer } from '../../../../components/ui';
import { ParticipationMap } from './ParticipationMap';
import { ParticipationTimeline } from './ParticipationTimeline';
import { ToggleSwitch } from '../../../../components/ui/ToggleSwitch';

export function MapAndGraph({
  region: { id, type, keyword, location, viewport, country },
  activeSoilSites,
}) {
  const [showMapAndGraph, setShowMapAndGraph] = useState(true);
  const [view, setView] = useState<'grid' | 'list'>('grid');

  useEffect(() => !showMapAndGraph && setShowMapAndGraph(true), [id]);

  return (
    <Container>
      <ControlPanel>
        <ToggleSwitchContainer>
          <ToggleSwitch
            label="SHOW MAP & GRAPH"
            name="map-graph"
            on={showMapAndGraph}
            onToggle={setShowMapAndGraph}
          />
        </ToggleSwitchContainer>

        {/* <ViewSwitch>
          <Revealer
            isOpen={showMapAndGraph}
            fade
            scale={false}
            height={false}
            duration={0.5}
          >
            <Switch onSelected={setView} defaultValue={view}>
              <Switch.SwitchButton value="grid">
                <FontAwesomeIcon icon="grip-vertical" />
                GRID
              </Switch.SwitchButton>
              <Switch.SwitchButton value="list">
                <FontAwesomeIcon icon="list" />
                LIST
              </Switch.SwitchButton>
            </Switch>
          </Revealer>
        </ViewSwitch> */}
      </ControlPanel>

      <Revealer isOpen={showMapAndGraph}>
        <Content>
          <Grid view={view} gap="1rem">
            <ParticipationMap
              location={location}
              viewport={viewport}
              soilSites={activeSoilSites}
            />

            <ParticipationTimeline region={{ id, keyword, type, country }} />
          </Grid>
        </Content>
      </Revealer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fafafacf;
  border-radius: 0 0 4px 4px;
`;

const ControlPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.35rem;

  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  border-top: 1px solid #d7d7d7;
  border-radius: 0 0 4px 4px;
`;

const ViewSwitch = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
  }
`;

const ToggleSwitchContainer = styled.div`
  background-color: #f8f8f8;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  font-size: 0.75rem;

  border: 1px solid #d7d7d7;
  border-radius: 4px 16px 16px 4px;
`;

const Content = styled.div`
  padding: 0 1rem 1rem;
`;
