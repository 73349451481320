import React, { useState } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Flex, Loader } from '../../../../components/ui';
import { Icon } from '../components/Components';
import { ImpactGrid } from './ImpactGrid';
import Switch from '../../../../components/Switch';
import { ImpactType, ImpactBase } from '../model';

const IMPACT_DATA = gql`
  query ImpactData($region: RegionInput!) {
    impactInRegion(region: $region) {
      #dropOffs {
      #  siteId
      #  userId
      #  weight
      #}
      foodWasteInPounds
      householdsParticipating
    }
  }
`;

export type ImpactProps = {
  region: { id: number; keyword: string; type: string; country: string };
  showDropOffBasedImpact?: boolean;
};

export const Impact: React.FC<ImpactProps> = ({
  region,
  showDropOffBasedImpact = false,
}) => {
  const { loading, data } = useQuery(IMPACT_DATA, {
    variables: {
      region: {
        id: region.id,
        type: region.type,
        keyword: region.keyword,
        country: region.country,
      },
    },
  });

  const [impactType, setImpactType] = useState<ImpactType>('accumulated');
  const [impactBase, setImpactBase] = useState<ImpactBase>('membership');

  if (!data && loading) return <Loader />;

  const {
    dropOffs,
    foodWasteInPounds,
    householdsParticipating,
  } = data.impactInRegion;

  return (
    <AnimatePresence exitBeforeEnter>
      <Container>
        <Header>
          <Flex gap="0.5rem" align="center">
            <Icon>
              <FontAwesomeIcon icon="trophy" />
            </Icon>

            <strong>IMPACT</strong>
          </Flex>

          <Switch onSelected={setImpactType} defaultValue={impactType}>
            <Switch.SwitchButton value={'accumulated'}>
              total so far
            </Switch.SwitchButton>
            <Switch.SwitchButton value={'current-rate'}>
              current rate
            </Switch.SwitchButton>
          </Switch>
        </Header>

        <MotionContainer
          key={impactType}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {showDropOffBasedImpact && impactType === 'accumulated' && (
            <Header>
              <div />
              <Flex gap="0.5rem" align="center">
                <HideMobile>
                  <h5 style={{ fontSize: '0.85rem', margin: 0 }}>BASED ON</h5>
                </HideMobile>

                <Switch<ImpactBase>
                  onSelected={setImpactBase}
                  defaultValue={impactBase}
                >
                  <Switch.SwitchButton value="membership">
                    length of participation
                  </Switch.SwitchButton>
                  <Switch.SwitchButton value={'dropOff'}>
                    drop-offs recorded
                  </Switch.SwitchButton>
                </Switch>
              </Flex>
            </Header>
          )}

          <Content>
            <ImpactGrid
              impactType={impactType}
              impactBase={impactBase}
              householdsParticipating={householdsParticipating}
              foodWasteInPounds={foodWasteInPounds}
              dropOffs={dropOffs}
            />
          </Content>
        </MotionContainer>
      </Container>
    </AnimatePresence>
  );
};

const HideMobile = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const Container = styled(motion.div)`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;

  h4 {
    margin: 0;
  }
`;

const MotionContainer = styled(motion.div)``;

const Content = styled.div`
  padding: 1rem;
`;
